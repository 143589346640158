module.exports = {
  siteTitle: 'Jorge Cepeda y Asociados', // <title>
  manifestName: 'directive',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/jcyasociados/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Jorge Cepeda y Asociados',
  subHeading: 'Estudio Jurídico Integral',
  // social
  socialLinks: [
    {
      icon: 'fa-whatsapp',
      name: 'Whatsapp',
      url: 'tel:02983-15644974',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/jorgecepedayasociados',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/jorgecepedayasociados',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:estudiojorgecepeda@gmail.com',
    },
  ],
};
