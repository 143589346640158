import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <Header />
    <div id="main">
      <div className="major container medium">
        <header className="major">
          <h2>El formulario fue enviado con éxito</h2>
          <p>Nos comunicaremos con usted a la brevedad.</p>
          <p><Link to="/" className="button">
              Volver
            </Link></p>
        </header>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
